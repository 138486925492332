import store from "app/store";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { setExpert } from "features/auth/authSlice";
import BrowserStorage from "utils/broswer-storage";

export function addAuthHeader(http: AxiosInstance): AxiosInstance {

    http.interceptors.request.use(async (req) => {
        const auth: any = await BrowserStorage.getItem("expertadmin");
        return {
            ...req,
            headers: {
                ...req.headers,
                authorization: auth ? `Bearer ${auth?.authorizationToken}` : undefined,
            },
        }
    });

    http.interceptors.response.use(
        (response: AxiosResponse): any => {
            if (response.status >= 200 && response.status < 300) {
                return response;
            }
        }, (error: AxiosError) => {
            const {
                response
            }: { response?: AxiosResponse; } = error;
            if (response) {
                if (response.status === 401 || response.status === 403) {
                    store.dispatch(setExpert(undefined));
                    window.location.href = '/';
                }
            }
            return Promise.reject(error);
        }
    );

    return http;
}