import { SendEmail } from 'types';
import { API } from 'utils/constants';
import { post } from './evaluationPlatformApi';

const baseUrl = process.env.REACT_APP_API_URL;

const sendEmails = (payload: SendEmail) => {
    return post(baseUrl + API.SENDEMAILSAPI, payload);
};

export const sendEmailsApi = {
    sendEmails,
};