import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationFilters, UserStatisticsDataCount, UserStatisticsDataTrack } from 'types';
import { DefaultPaginationCount } from 'utils/constants';
import { getResultForUserActivitySearch, sendEmailsToUsers } from './tools.Actions';

interface IInitialState {
    loading: boolean;
    success: boolean;
    paginationFilter: PaginationFilters;
    mailStatus: {
        filteredEmails: any[];
        totalEmails: number;
    },
    userStatistics: {
        totalUserStatusInfo: number;
        userStatisticsData: UserStatisticsDataCount[] | UserStatisticsDataTrack[];
    }
}

const initialState: IInitialState = {
    loading: false,
    success: false,
    paginationFilter: { count: DefaultPaginationCount, skipCount: 0 },
    mailStatus: {
        filteredEmails: [],
        totalEmails: 0,
    },
    userStatistics: {
        totalUserStatusInfo: 0,
        userStatisticsData: [],
    }
};

const toolsSlice = createSlice({
    name: 'tools',
    initialState,
    reducers: {
        setPaginationFilter(state, { payload }: PayloadAction<PaginationFilters>) {
            if (payload.count != undefined) state.paginationFilter.count = payload.count;
            state.paginationFilter.skipCount = payload.skipCount;
        },
        resetStatus: (state) => ({ ...state, loading: initialState.loading, success: initialState.success }),
    },
    extraReducers: (builder) => {
        builder.addCase(sendEmailsToUsers.pending, (state) => {
            state.success = false;
            state.loading = true;
        });
        builder.addCase(sendEmailsToUsers.fulfilled, (state) => {
            state.success = true;
            state.loading = false;
        });
        builder.addCase(sendEmailsToUsers.rejected, (state) => {
            state.success = false;
            state.loading = false;
        });
        builder.addCase(getResultForUserActivitySearch.pending, (state) => {
            state.success = false;
            state.loading = true;
        });
        builder.addCase(getResultForUserActivitySearch.fulfilled, (state, action) => {
            state.success = true;
            state.userStatistics.userStatisticsData = action.payload?.data?.output?.tracks || action.payload?.data?.output?.candidates;
            state.userStatistics.totalUserStatusInfo = action.payload?.data?.output?.totalCount;
            state.loading = false;
        });
        builder.addCase(getResultForUserActivitySearch.rejected, (state) => {
            state.userStatistics.userStatisticsData = [];
            state.userStatistics.totalUserStatusInfo = 0;
            state.success = false;
            state.loading = false;
        });
    },
});

export const { resetStatus, setPaginationFilter } = toolsSlice.actions;
export default toolsSlice.reducer;