import { GetCandidateActivity, GetUserStatistics } from 'types';
import { API } from 'utils/constants';
import { post } from './evaluationPlatformApi';

const baseUrl = process.env.REACT_APP_API_URL;

const filterUserStatistics = (payload: GetUserStatistics) => {
    return post(baseUrl + API.GET_USER_STATISTICS_API, payload);
};

const getCandidateActivity = (payload: GetCandidateActivity) => {
    return post(baseUrl + API.GET_CANDIDATE_ACTIVITY, payload);
};

export const getUserStatisticsApi = {
    filterUserStatistics,
    getCandidateActivity,
};