import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendEmailsApi } from 'api/mailToolApi';
import { getUserStatisticsApi } from 'api/userStatisticsApi';
import { GetUserStatistics, SendEmail } from 'types';

export const sendEmailsToUsers = createAsyncThunk(
    `evaluationPlatformExpertAdmin/sendEmail`,
    async (payload: SendEmail) => {
        const response = await sendEmailsApi.sendEmails(payload);
        return response;
    }
);

export const getResultForUserActivitySearch = createAsyncThunk(
    `expert/getResultForUserActivitySearch`,
    async (payload: GetUserStatistics) => {
        const response = await getUserStatisticsApi.filterUserStatistics(payload);
        return response;
    }
);